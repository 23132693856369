import { put, call, select } from 'redux-saga/effects'
import { query } from 'domain/router/reducer'
import { push } from 'connected-react-router'
import { FormErrors, stopSubmit } from 'redux-form'
import { checkSignUp, persistToken, transformUser, checkInviteRecruiter } from './helpers'
import I from 'immutable'
import Api from 'domain/api'
import { modalIsLoading, pageIsLoading } from 'domain/loading'
import * as Actions from './actions'
import * as M from './index'
import * as storage from 'lib/storage'
import * as Router from 'domain/router'

const TOKEN = 'jwt'

interface FormValuesInterface {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmationPassword: string
  accept: boolean
  campaign?: string
  favoriteJobs?: number[]
  country?: { value: string | number }
}

export function* ensureSignUpManager({ payload }: { payload: FormValuesInterface; type: string }) {
  const { accept, ...rest } = payload
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data },
      headers
    } = yield call(Api.signUpManager, { data: rest })
    const tokens = headers[TOKEN]
    if (tokens) persistToken(tokens)
    yield put({
      type: Actions.signUpManager.success,
      payload: transformUser(I.fromJS(data)),
      tokens: I.fromJS(tokens)
    })
  } catch (err) {
    yield put({
      type: Actions.signUpManager.failure,
      err
    })
    yield put(stopSubmit('sign-up', checkSignUp(err.response.data.message)))
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureSignUpRecruiter({
  payload
}: {
  payload: FormValuesInterface
  type: string
}) {
  const { accept, ...rest } = payload
  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data },
      headers
    } = yield call(Api.signUpRecruiter, { data: rest })
    const tokens = headers[TOKEN]
    if (tokens) persistToken(tokens)
    yield put({
      type: Actions.signUpRecruiter.success,
      payload: transformUser(I.fromJS(data)),
      tokens: I.fromJS(tokens)
    })
  } catch (err) {
    yield put({
      type: Actions.signUpRecruiter.failure,
      err
    })
    yield put(stopSubmit('sign-up', checkSignUp(err.response.data.message)))
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureSignUpCandidate({
  payload
}: {
  payload: FormValuesInterface
  type: string
}) {
  const routeMatch = yield select(Router.matchRoutes)
  const campaign = routeMatch && routeMatch.params && routeMatch.params.campaign

  const search = yield select(Router.search)
  const { accept, country, ...rest } = payload

  try {
    yield put({ type: modalIsLoading, payload: true })
    const {
      data: { data },
      headers
    } = yield call(Api.signUpInvitedCandidate, {
      data: {
        country: country && country.value,
        campaign,
        ...rest
      },
      search
    })
    const tokens = headers[TOKEN]
    if (tokens) persistToken(tokens)
    yield put({
      type: Actions.signUpCandidate.success,
      payload: transformUser(I.fromJS(data)),
      tokens: I.fromJS(tokens)
    })
  } catch (err) {
    yield put({
      type: Actions.signUpCandidate.failure,
      err
    })
    yield put(stopSubmit('sign-up', checkSignUp(err.response.data.message)))
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureSignIn({
  payload
}: {
  payload: { email: string; password: string }
  type: string
}) {
  try {
    const {
      data: { data },
      headers
    } = yield call(Api.signIn, { data: payload })
    const tokens = headers[TOKEN]
    if (tokens) persistToken(tokens)
    yield put({
      type: Actions.signIn.success,
      payload: transformUser(I.fromJS(data)),
      tokens: I.fromJS(tokens)
    })
  } catch (err) {
    yield put({ type: Actions.signIn.failure, err })
    const { message, statusCode } = err.response.data
    const error: FormErrors<{ password: string } | { email: string }> =
      statusCode === 403 ? { password: message } : { email: message }
    yield put(stopSubmit('log-in', error))
  }
}

export function* checkAuth() {
  const headers = yield select(M.userToken)
  if (headers) {
    try {
      const verifyToken = yield call(Api.verifyToken, {
        headers: { Authorization: `Bearer ${headers}` }
      })
      if (verifyToken) {
        const {
          data: { data }
        } = yield call(Api.checkAuth, {
          headers: { Authorization: `Bearer ${headers}` }
        })
        yield put({
          type: Actions.checkAuth.success,
          payload: transformUser(I.fromJS(data)),
          tokens: I.fromJS(headers)
        })
      } else {
        throw new Error('Token is wrong!')
      }
    } catch (err) {
      storage.clearStorage()
      yield put({
        type: Actions.checkAuth.failure,
        err
      })
    }
  } else {
    yield put({
      type: Actions.checkAuth.failure
    })
  }
}

export function* checkConfirmation() {
  const { token } = yield select(query)
  if (token) {
    try {
      const {
        data: { data }
      } = yield call(Api.confirmation, { data: { confirmationToken: token } })
      yield put({
        type: Actions.confirmation.success,
        payload: I.fromJS(data)
      })
      yield put(push('/log-in'))
    } catch (err) {
      yield put({
        type: Actions.confirmation.failure,
        err
      })
    }
  } else {
    yield put({
      type: Actions.confirmation.failure
    })
  }
}

interface RecruiterConfirmationInterface {
  firstName: string
  lastName: string
  password: string
  confirmationPassword: string
}

export function* recruiterConfirmation({
  payload
}: {
  payload: RecruiterConfirmationInterface
  type: string
}) {
  const { token, email } = yield select(query)
  if (token) {
    try {
      const {
        data: { data }
      } = yield call(Api.recruiterAcceptInvitation, {
        data: { invitationToken: token, ...payload, email }
      })
      yield put({
        type: Actions.recruiterAccept.success,
        payload: I.fromJS(data)
      })
    } catch (err) {
      yield put({
        type: Actions.recruiterAccept.failure,
        err
      })
      yield put(stopSubmit('create-password-recruiter', checkInviteRecruiter(err.response.data)))
    }
  } else {
    yield put({
      type: Actions.confirmation.failure
    })
  }
}

export function* acceptRecommendationJob({
  payload
}: {
  payload: RecruiterConfirmationInterface
  type: string
}) {
  const { token, email } = yield select(query)
  if (token) {
    try {
      const {
        data: { data }
      } = yield call(Api.acceptRecommendJob, {
        data: { token, ...payload, email }
      })
      yield put({
        type: Actions.acceptRecommendJob.success,
        payload: I.fromJS(data)
      })
    } catch (err) {
      yield put({
        type: Actions.acceptRecommendJob.failure,
        err
      })
      yield put(stopSubmit('create-password-recruiter', checkInviteRecruiter(err.response.data)))
    }
  } else {
    yield put({
      type: Actions.confirmation.failure
    })
  }
}

export function* ensureRecoveryPassword({ payload }: { payload: { email: string }; type: string }) {
  try {
    const {
      data: { data }
    } = yield call(Api.recoveryPassword, { data: payload })
    yield put({
      type: Actions.recoveryPassword.success,
      payload: I.fromJS(data)
    })
  } catch (err) {
    const error: FormErrors<{ email: string }> = { email: err.response.data.message }
    yield put({
      type: Actions.recoveryPassword.failure,
      err
    })
    yield put(stopSubmit('reset-password', error))
  }
}

export function* ensureUpdatePassword({
  payload
}: {
  payload: { password: string; confirmationPassword: string }
  type: string
}) {
  const { token } = yield select(query)
  if (token) {
    try {
      const { data } = yield call(Api.updatePassword, {
        data: {
          token,
          ...payload
        }
      })
      yield put({
        type: Actions.updatePassword.success,
        payload: I.fromJS(data)
      })
    } catch (err) {
      const error: FormErrors<{ password: string }> = { password: err.response.data.message }
      yield put({
        type: Actions.updatePassword.failure,
        err
      })
      yield put(stopSubmit('create-password', error))
    }
  } else {
    yield put({
      type: Actions.updatePassword.failure
    })
  }
}

export function* ensureCheckUserEmail(props: { payload: { email: string }; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.checkUserEmail, {
      headers: { Authorization: `Bearer ${headers}` },
      params: props.payload
    })
    yield put({ type: Actions.checkUserEmail.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.checkUserEmail.FAILURE,
      err
    })
  }
}

export function* ensureSuperManagerRequest() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: pageIsLoading, payload: true })
    yield call(Api.superManagerRequest, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.superManagerRequest.success })
  } catch (err) {
    yield put({
      type: Actions.superManagerRequest.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
