import { Styles } from 'react-jss'

export const sheet: Styles = {
  formGroup: {
    whiteSpace: 'nowrap',
    position: 'relative'
  },
  formText: {
    color: '#dc233b',
    fontSize: 12,
    margin: '12px 0 0'
  },
  error: {},
  disabled: {},
  formCheckInput: {
    position: 'absolute',
    opacity: 0,
    '& + label': {
      position: 'relative',
      cursor: 'pointer',
      fontSize: 15,
      padding: 0
    },
    '& + label:before': {
      content: '""',
      marginRight: 10,
      display: 'inline-block',
      verticalAlign: 'text-top',
      width: 20,
      height: 20,
      background: '#FFFFFF',
      border: '2px solid #E6E6E6',
      boxSizing: 'border-box',
      borderRadius: 1
    },
    '& + label:before.error': {
      border: '2px solid #FF1717'
    },
    '&:checked + label:before': {
      background: '#286EFA',
      border: 'none'
    },
    '&:disabled + label': {
      color: '#888888',
      cursor: 'auto'
    },
    '&:disabled + label:before': {
      background: '#FFFFFF',
      border: '2px solid #E6E6E6'
    },
    '&:checked + label:after': {
      content: '""',
      position: 'absolute',
      left: 5,
      top: 9,
      background: 'white',
      width: 2,
      height: 2,
      boxShadow:
        '2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white',
      transform: 'rotate(45deg)'
    },
    '&:focus + label:before': {
      borderColor: '#286EFA',
      boxShadow: '0 0 3px #286EFA'
    }
  }
}
